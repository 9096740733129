<template>
    <div class="l-padded-x">
        <div class="form-section">
            <h3 class="t-title l-inline l-spread l-center-v">
                {{ $t('report') }}

                <div class="l-inline l-gap-2">
                    <BaseButton
                        :disabled="isLoading"
                        size="small"
                        @click="handleSubmit"
                    >
                        {{ $t('shared.save') }}
                    </BaseButton>

                    <IconButton @click="goToProjectDetails">
                        <RemoveIcon />
                    </IconButton>
                </div>
            </h3>

            <div
                v-if="!isLoading"
                class="l-padded-y l-inline l-gap-3 form-section__split"
            >
                <div class="l-stack l-gap-2">
                    <label>
                        <p class="form-label">{{ $t('day') }}</p>

                        <BaseInput
                            :value="reportWeekday"
                            :placeholder="$t('day')"
                            disabled
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('date') }}</p>

                        <BaseInput
                            v-model="$v.reportData.date.$model"
                            :placeholder="$t('date')"
                        />

                        <p
                            v-if="
                                $v.reportData.date.$dirty &&
                                    $v.reportData.date.$invalid
                            "
                            class="form-error"
                        >
                            {{
                                !$v.reportData.date.required
                                    ? $t('valueRequired')
                                    : $t('valueInvalid')
                            }}
                        </p>
                    </label>
                </div>

                <div class="l-stack l-gap-2">
                    <label>
                        <p class="form-label">{{ $t('author') }}</p>

                        <BaseInput
                            v-model="reportData.author"
                            :placeholder="$t('author')"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('phone') }}</p>

                        <BaseInput
                            v-model="reportData.phone"
                            :placeholder="$t('phone')"
                        />
                    </label>
                </div>
            </div>
        </div>

        <VSpinner
            v-if="isLoading"
            class="l-padded"
            size="medium"
            line-fg-color="black"
            line-bg-color="transparent"
            :speed="1"
        />

        <template v-else>
            <div class="form-section">
                <h3 class="t-title">
                    {{ $t('description') }}
                </h3>

                <div class="l-padded-y">
                    <BaseInput
                        v-model="reportData.description"
                        :textarea="true"
                        :placeholder="$t('description')"
                    />
                </div>
            </div>

            <div class="form-section">
                <h3 class="t-title">
                    {{ $t('measurements') }}
                </h3>

                <div class="l-padded-y l-inline l-gap-3 form-section__split">
                    <div class="l-stack l-gap-2">
                        <label>
                            <p class="form-label">{{ $t('name') }}</p>

                            <BaseMultiselect
                                v-model="selectedAsset"
                                :placeholder="$t('name')"
                                :options="assetOptions"
                                track-by="id"
                                :custom-label="
                                    option => option.asset_details.name
                                "
                            />
                        </label>
                    </div>

                    <div>
                        <div
                            v-if="isLoadingChart"
                            class="l-stack l-center l-padded"
                        >
                            <VSpinner
                                size="medium"
                                line-fg-color="#000"
                                :speed="1"
                            />
                        </div>

                        <ApexChart
                            v-else
                            height="400"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'
import ApexChart from 'vue-apexcharts'
import moment from 'moment'

import { formatHelper, httpHelper } from '@/utils'
import BaseButton from '../redesigned/BaseButton'
import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ChartHelper from '@/mixins/ChartHelper'
import IconButton from '../IconButton'
import RemoveIcon from '../icons/RemoveIcon'

const isDate = value =>
    value?.length === 10 && moment(value, 'DD.MM.YYYY').isValid()

export default {
    name: 'ConstructionProjectReportView',
    components: {
        ApexChart,
        BaseButton,
        BaseInput,
        BaseMultiselect,
        IconButton,
        RemoveIcon,
        VSpinner,
    },
    mixins: [ChartHelper],
    data() {
        return {
            chartDataTypes: ['humidity', 'temperature'],
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 5,
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ['#0077c2', process.env.VUE_APP_COLOR_ACCENT],
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: {
                    decimalsInFloat: 2,
                    min: -25,
                    max: 100,
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            chartSeries: [],
            isLoading: false,
            isLoadingChart: false,
            reportData: {
                date: null,
            },
            selectedAsset: null,
        }
    },
    validations() {
        return {
            assetOptions: [],
            reportData: {
                date: {
                    required,
                    isDate,
                },
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        reportDate() {
            return isDate(this.reportData.date)
                ? moment(this.reportData.date, 'DD.MM.YYYY').format(
                      'YYYY-MM-DD'
                  )
                : null
        },
        reportWeekday() {
            return this.reportDate
                ? formatHelper.getWeekday(this.reportDate, this.$i18n.locale)
                : null
        },
    },
    watch: {
        '$route.params.reportId': {
            immediate: true,
            async handler() {
                const { reportId } = this.$route.params

                if (!reportId) {
                    this.reportData = {
                        date: null,
                    }
                    this.assetOptions = []
                    return
                }

                try {
                    this.isLoading = true
                    const { data } = await httpHelper.get(
                        `/daily-reports/${this.$route.params.reportId}/`
                    )
                    this.reportData = {
                        ...data,
                        date: moment(data.date).format('DD.MM.YYYY'),
                    }
                    this.assetOptions = this.trackers.filter(
                        tracker =>
                            data.project.deployedasset_set.find(
                                ({ asset }) => tracker.asset === asset
                            ) &&
                            this.chartDataTypes.some(
                                dataType =>
                                    dataType in
                                    tracker.asset_details.sensor_data
                            )
                    )
                    if (this.assetOptions.length) {
                        this.selectedAsset = this.assetOptions[0]
                    }
                    this.isLoading = false
                } catch {
                    this.reportData = {
                        date: null,
                    }
                    this.assetOptions = []
                }
            },
        },
        reportDate() {
            this.loadChartData()
        },
        selectedAsset() {
            this.loadChartData()
        },
    },
    methods: {
        goToProjectDetails() {
            this.$router.push({
                name: 'constructionProjectDetails',
                params: { id: this.$route.params.projectId },
            })
        },
        async handleSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            try {
                this.isLoading = true
                await httpHelper.post('daily-reports/', {
                    id: this.reportData.id,
                    project: +this.$route.params.projectId,
                    date: this.reportDate,
                    author: this.reportData.author,
                    phone: this.reportData.phone,
                    description: this.reportData.description,
                })
                this.goToProjectDetails()
            } finally {
                this.isLoading = false
            }
        },
        async loadChartData() {
            if (!this.reportDate || !this.selectedAsset) {
                return
            }
            this.isLoadingChart = true
            await this.fetchChartData()
            this.isLoadingChart = false
        },
        async fetchChartData() {
            const momentDate = moment(this.reportDate)
            const timestampMin = momentDate.startOf('day').format()
            const timestampMax = momentDate.endOf('day').format()
            const results = []

            let url =
                'measurements/?' +
                `tracker=${this.selectedAsset.id}` +
                `&timestamp_min=${encodeURIComponent(timestampMin)}` +
                `&timestamp_max=${encodeURIComponent(timestampMax)}` +
                '&fields=timestamp,sensor_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            const chartData = this.chartDataTypes.map(dataType => {
                return results
                    .filter(item =>
                        Object.prototype.hasOwnProperty.call(
                            item.sensor_data,
                            dataType
                        )
                    )
                    .map(item => [item.timestamp, item.sensor_data[dataType]])
            })

            this.chartSeries = chartData.map((data, i) => ({
                name: this.$t(`shared.measurements.${this.chartDataTypes[i]}`),
                data,
            }))
        },
    },
}
</script>

<i18n>
{
    "en": {
        "author": "Freigabe",
        "date": "Date",
        "day": "Day",
        "description": "Informationen / Tätigkeiten",
        "measurements": "Measurements",
        "name": "Name",
        "phone": "Phone number",
        "report": "Daily report",
        "valueInvalid": "Invalid value",
        "valueRequired": "Value is required"
    },
    "de": {
        "author": "Freigabe",
        "date": "Date",
        "day": "Tag",
        "description": "Informationen / Tätigkeiten",
        "measurements": "Messungen",
        "name": "Name",
        "phone": "Telefon",
        "report": "Daily report",
        "valueInvalid": "Invalid value",
        "valueRequired": "Value is required"
    },
    "fr": {
        "author": "Freigabe",
        "date": "Date",
        "day": "Jour",
        "description": "Informations / Activités",
        "measurements": "Mesures",
        "name": "Nom",
        "phone": "Numéro de téléphone",
        "report": "Rapports quotidien",
        "valueInvalid": "Valeur invalide",
        "valueRequired": "Une valeur est nécessaire"
    },
    "it": {
        "author": "Freigabe",
        "date": "Date",
        "day": "Day",
        "description": "Informationen / Tätigkeiten",
        "measurements": "Measurements",
        "name": "Name",
        "phone": "Phone number",
        "report": "Daily report",
        "valueInvalid": "Invalid value",
        "valueRequired": "Value is required"
    }
}
</i18n>

<style lang="scss" scoped>
.form-section {
    &__split {
        & > * {
            flex: 1;
        }
    }

    h3 {
        position: sticky;
        top: 0;
        padding-top: 1rem;
        background-color: #fff;
        z-index: 10;
    }
}

.form-error {
    margin: 0.5rem 0 0;
}
</style>
